'use client'

import Image from 'next/image'
import { useRouter } from 'next/navigation'

export function BackButton() {
  const router = useRouter()

  return (
    <button
      onClick={() => {
        router.back()
      }}
    >
      <Image
        className="drop-shadow-[2px_2px_0_rgba(0,0,0,0.25)]"
        src="/images/back-button.svg"
        alt="Go back"
        height={64}
        width={64}
      />
    </button>
  )
}
