import { useQuery } from '@tanstack/react-query'
import type {
  QueryClient,
  QueryFilters,
  SetDataOptions,
  Updater,
} from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { getCoins, getCoinsEarned } from '@/data/coins'

interface UseCoinsProps {
  studentId: string
  activityId?: string | null | undefined
}

const queryKeys = {
  coins() {
    return [{ scope: 'coins' }] as const
  },
  studentCoins(studentId: string) {
    const [scope] = this.coins()
    return [{ ...scope, studentId }] as const
  },
  activityCoins(studentId: string, activityId?: string | null | undefined) {
    return [{ scope: 'coins-earned', studentId, activityId }] as const
  },
}

export function setCoinsQueryData(
  client: QueryClient,
  studentId: string,
  coinCount: Updater<number | undefined, number | undefined>,
  options?: SetDataOptions,
) {
  return client.setQueryData(
    queryKeys.studentCoins(studentId),
    coinCount,
    options,
  )
}

export function getCoinsQueryData(
  client: QueryClient,
  studentId: string,
  filters?: QueryFilters,
): number | undefined {
  return client.getQueryData(queryKeys.studentCoins(studentId), filters)
}

export function prefetchCoinsQuery(
  client: QueryClient,
  studentId: string,
  init?: RequestInit,
) {
  return client.prefetchQuery(queryKeys.studentCoins(studentId), () =>
    getCoins(studentId, init),
  )
}

export function invalidateCoinQueries(
  client: QueryClient,
  studentId: string,
  activityId?: string | null | undefined,
) {
  return client.invalidateQueries({
    queryKey: queryKeys.activityCoins(studentId, activityId),
  })
}

export function cancelStudentCoinQueries(
  client: QueryClient,
  studentId: string,
) {
  return client.cancelQueries({
    queryKey: queryKeys.studentCoins(studentId),
  })
}

export function useCoins({ studentId, activityId }: UseCoinsProps) {
  const coinBalanceResults = useQuery({
    queryKey: queryKeys.studentCoins(studentId),
    queryFn: async ({ signal = null }) => getCoins(studentId, { signal }),
  })

  const coinEarnedResults = useQuery({
    enabled: Boolean(activityId),
    queryKey: queryKeys.activityCoins(studentId, activityId),
    queryFn: async ({ signal = null }) => {
      invariant(typeof activityId === 'string', 'activityId must be a string')

      return getCoinsEarned({ studentId, activityId }, { signal })
    },
  })

  const coinsEarned = coinEarnedResults.data?.coinsEarned ?? 0
  const noActivityWithCoinRewardFound = coinsEarned == 0

  return { coinCount: coinBalanceResults.data, noActivityWithCoinRewardFound }
}
