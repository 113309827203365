'use client'

import type { SVGProps } from 'react'
import { useCoins } from '@/lib/use-coins'

interface CoinCounterProps {
  studentId: string
}

export function CoinCounter({ studentId }: CoinCounterProps) {
  const { coinCount } = useCoins({ studentId })

  return (
    <div className="flex content-center m-2 bg-[#4077FC] border-[6px] border-white rounded-[20px] drop-shadow-[2px_2px_0_rgba(0,0,0,0.25)]">
      <CoinSvg height={78} width={78} className="-m-4" />
      <p className="pl-5 pr-[6px] leading-tight text-4xl text-white text-shadow-md">
        {coinCount} <span className="sr-only">coins</span>
      </p>
    </div>
  )
}

export function CoinSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 66.27 62.64"
      {...props}
    >
      <path
        fill="#fff"
        d="M34.81 62.64c-8 0-16.3-1.47-22.44-6.54A31.86 31.86 0 0 1 2.1 42.52C-5.93 22 10.12-1.07 32.35 0 41.85-.16 51 3.19 56.92 9c19.86 20.31 6.02 53.77-22.11 53.64Zm-30-37.7Z"
      />
      <path
        fill="#e37703"
        d="M62.18 34.83C57 20.08 48.86 1.09 23.88 6.6c-7.95 1.76-13.6 10.67-15.27 18.59a27.31 27.31 0 0 0 10.11 28c16.42 12.6 41.1 2.19 43.46-18.38"
      />
      <path
        fill="#e3a303"
        d="M35.24 4.13c-4.32-.22-8 .14-11.36 2.47C53.64 0 55 25.77 58.81 33.51c1 0 2.21.91 3.37 1.32a31.85 31.85 0 0 0-3.93-17.78 25.89 25.89 0 0 0-3.9-4.92 28.89 28.89 0 0 0-19.11-8Z"
      />
      <path
        fill="#f9c74a"
        d="M56 13.94a9.11 9.11 0 0 0-1.7-1.87c-1.61-.66-2.72-.39-4.2-.49l1.6 1.63c1.35.14 2.92.47 4.3.73Z"
      />
      <path
        fill="#ffd400"
        d="M50.49 50.49a23.83 23.83 0 0 0 2.73-3.12 219.45 219.45 0 0 0-26.53 10.71 27 27 0 0 0 23.8-7.59M9.55 33.08c-1.62 1.42-3.25 2.84-4.86 4.27.36 1.3.71 2.51 1.15 3.75 1.48-1.21 3-2.41 4.48-3.58-.12-.48-.77-4.44-.77-4.44M20 50.19a23.36 23.36 0 0 1-8.41-9.26q-2.07 1.76-4.13 3.54A29.54 29.54 0 0 0 14.84 53c1.71-1 3.41-1.9 5.16-2.81M46.74 16a22 22 0 0 1 6.14 19.1c1.84-.67 3.68-1.31 5.55-1.92 0-.4-2.13-14.9-6.74-20-1.66.92-3.3 1.88-4.95 2.82m-8.19-5.17a20.31 20.31 0 0 1 6.27 3.46c1.69-1 3.36-2 5-2.94a26 26 0 0 0-5.68-4.15c-1.86 1.2-3.73 2.42-5.59 3.66M32.28 4C19.54 3.24 7.44 12.6 4.77 24.9 8.4 21.89 19.39 13.05 21.32 12 25 9.35 28.57 6.63 32.28 4Z"
      />
      <path
        fill="#ffc000"
        d="M14.84 53a25.59 25.59 0 0 0 11.85 5.12 219.45 219.45 0 0 1 26.53-10.75 25.76 25.76 0 0 0 5.21-14.16c-1.87.61-3.71 1.25-5.55 1.92A21.09 21.09 0 0 1 46.78 47a21.16 21.16 0 0 1-15.48 6.3A21.19 21.19 0 0 1 20 50.19c-1.75.91-3.45 1.81-5.16 2.81Z"
      />
      <path
        fill="#ffe66f"
        d="M5.88 41.15a17.19 17.19 0 0 0 1.54 3.32q2.05-1.78 4.13-3.54a19 19 0 0 1-1.23-3.41c-1.5 1.17-3 2.37-4.48 3.58M4.77 24.94a30.34 30.34 0 0 0-.08 12.41c1.61-1.43 3.24-2.85 4.86-4.27v-1.57a21.16 21.16 0 0 1 6-15c-2.01.36-8.19 6.32-10.78 8.43Zm40.05-10.62A22.13 22.13 0 0 1 46.74 16c1.65-.94 3.29-1.9 4.95-2.82-.58-.6-1.19-1.27-1.84-1.83-1.67.95-3.34 1.93-5 2.94M31.3 9.71a23.47 23.47 0 0 1 7.25 1.15c1.86-1.24 3.73-2.46 5.59-3.66A27 27 0 0 0 32.28 4c-3.71 2.59-7.3 5.31-11 8A21.63 21.63 0 0 1 31.3 9.71Z"
      />
      <path
        fill="#ffca00"
        d="M46.44 17.73c-12.38-12.4-34.68-3.81-34.59 14-.22 11 9.4 20.07 20.26 19.83 17.77.21 27.21-21.27 14.33-33.83Z"
      />
      <path
        fill="#f8db4b"
        d="m18.76 45.26 5.08-14.57L38.7 16.24l6.98 4.01-.46 7.92L33.29 40.1l-14.53 5.16z"
      />
      <path
        fill="#e37703"
        d="M41.84 15.68a4 4 0 0 0-4 .56c-.03-.08-13.79 13.83-13.84 13.82-.83.16-5.22 13.49-5.67 14.37a1 1 0 0 0 1.32 1.32C20.5 45.3 33.87 40.81 34 40l13.46-13.48a4 4 0 0 0 0-5.68c-.71-.59-4.73-5-5.62-5.16M41.75 19c1.1 1.11-1 3.39-2.22 2.43-1-1.14 1-3.36 2.22-2.43m-7.12 1.5 1.19-1.19 8.92 8.87-1.24 1.24-5.5-5.55c-1.58 1.2-7.78 7.41-9.29 8.19L37 22.93l-2.37-2.43M32.67 40c0 .08-10 3.71-10 3.75a6.08 6.08 0 0 0-2.43-2.3l3.58-9.73a3.17 3.17 0 0 0 .35-.72c1.41.93 1.52 2.76 1.28 4.52 4.3-3.09 6.13-1 3.36 3.37 1.49-.27 3.2-.29 4.23.94-.19.06-.28.22-.37.17Z"
      />
      <path
        fill="#fbeb44"
        d="M47.51 38.54c-.12-4.61-6.92-4.7-7 0s6.89 4.63 7 0m-25.59-13a2.56 2.56 0 0 0-5.12 0c0 3.4 5.1 3.35 5.12 0m8.27-5.89c.27-6.44-9.72-7-10.23-.59-.23 6.51 9.81 7.15 10.23.63Z"
      />
      <path
        fill="#fff8e7"
        d="M18.24 48.9c-11.76-9.52-10.1-23.87-.83-34-5.82 3.94-8.48 9.54-8.75 16.44s3.09 13 10.09 18ZM57.5 32.07c.22 7.43-2.44 14.08-8.36 19.77 13.29-10 12.18-29.18 1.53-39.68a34.55 34.55 0 0 1 6.83 19.91Z"
      />
    </svg>
  )
}
