/**
 * If grade position is not available, default to DEFAULT_CONTENT_GRADE
 * Content Grade are bounded between MIN_CONTENT_GRADE and MAX_CONTENT_GRADE
 * These constants are supposed to be updated when we publish more activities for other grades
 */
export const DEFAULT_CONTENT_GRADE = '4'
export const MIN_CONTENT_GRADE = 1
export const MAX_CONTENT_GRADE = 7

/**
 * Convert grade position to EWI activity grade level based on grade set Id
 * https://blakeelearning.atlassian.net/wiki/spaces/WL/pages/2055012448/Grade#Student-Grade-Position---EWI-Content-mapping
 * @param gradePosition
 * @param gradeSetId
 * @returns EWI grade X that is used in `Grade X` activity tag in EWI activities
 */

export function toContentGradeFromGradePosition(
  gradePosition?: number | null,
  gradeSetId?: number | null,
): string {
  if (gradePosition === null || gradePosition === undefined) {
    return DEFAULT_CONTENT_GRADE
  }

  // adjusted grade position to be aligned with content grade
  // For most of Grade Sets, Grade = GradePosition - 1,
  // Except NZ (GradeSetId = 7), Grade = GradePosition - 2
  const adjustedGrade = gradeSetId === 7 ? gradePosition - 2 : gradePosition - 1

  const contentGrade = Math.min(
    MAX_CONTENT_GRADE,
    Math.max(adjustedGrade, MIN_CONTENT_GRADE),
  )

  return `${contentGrade}`
}
