'use client'

import { useStudent } from '@/lib/use-student'
import Image from 'next/image'

export function Avatar({ studentId }: { studentId: string }) {
  const { student } = useStudent(studentId)

  return (
    <>
      {student?.avatar && (
        <Image
          className="drop-shadow-[2px_2px_0_rgba(0,0,0,0.25)]"
          src={`https://content-assets.static.readingeggs.com/writing-legends/activities/common/images/avatars/${student.avatar}.png`}
          alt=""
          height={64}
          width={64}
        />
      )}
      {student?.name && (
        <div className="flex items-center lg:items-start lg:pt-2">
          <p className="ml-2 font-display text-lg lg:text-base leading-3">
            {student.name}
          </p>
        </div>
      )}
    </>
  )
}
