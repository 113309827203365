import { apiClient } from '@/lib/api-client'

export function getCoins(
  studentId: string,
  init?: RequestInit,
): Promise<number> {
  return apiClient.coinsApi.apiV1CoinsStudentIdBalanceGet({ studentId }, init)
}

interface GetCoinsEarned {
  studentId: string
  activityId: string
}

export async function getCoinsEarned(
  options: GetCoinsEarned,
  init?: RequestInit,
) {
  const response =
    await apiClient.coinsApi.apiV1CoinsStudentIdActivityActivityIdCoinsEarnedGet(
      options,
      init,
    )
  return response
}
