import { useQuery } from '@tanstack/react-query'
import type {
  QueryClient,
  SetDataOptions,
  Updater,
} from '@tanstack/react-query'
import { getStudent, getStudentPurchases } from '@/data/student'
import type { StudentAvatar, StudentProfile } from '@/data/student'

const queryKeys = {
  student(studentId: string) {
    return [{ scope: 'student', studentId }] as const
  },
  purchases(studentId: string) {
    return [{ scope: 'avatar-purchases', studentId }] as const
  },
}

export function setStudentQueryData(
  client: QueryClient,
  studentId: string,
  student: Updater<StudentProfile | undefined, StudentProfile | undefined>,
  options?: SetDataOptions,
) {
  return client.setQueryData(queryKeys.student(studentId), student, options)
}

export function getStudentQueryData(client: QueryClient, studentId: string) {
  return client.getQueryData<StudentProfile | undefined>(
    queryKeys.student(studentId),
  )
}

export function prefetchStudentQuery(
  client: QueryClient,
  studentId: string,
  init?: RequestInit,
) {
  return client.prefetchQuery(queryKeys.student(studentId), () =>
    getStudent(studentId, init),
  )
}

export function invalidateStudentQueries(
  client: QueryClient,
  studentId: string,
) {
  return client.invalidateQueries(queryKeys.student(studentId))
}

export function cancelStudentQueries(client: QueryClient, studentId: string) {
  return client.cancelQueries(queryKeys.student(studentId))
}

export function prefetchStudentPurchasesQuery(
  client: QueryClient,
  studentId: string,
  init?: RequestInit,
) {
  return client.prefetchQuery(queryKeys.purchases(studentId), () =>
    getStudentPurchases(studentId, init),
  )
}

export function getStudentPurchasesQueryData(
  client: QueryClient,
  studentId: string,
): StudentAvatar[] | undefined {
  return client.getQueryData(queryKeys.purchases(studentId))
}

export function setStudentPurchasesQueryData(
  client: QueryClient,
  studentId: string,
  studentAvatars: Updater<
    StudentAvatar[] | undefined,
    StudentAvatar[] | undefined
  >,
  options?: SetDataOptions,
) {
  return client.setQueryData(
    queryKeys.purchases(studentId),
    studentAvatars,
    options,
  )
}

export function cancelStudentPurchaseQueries(
  client: QueryClient,
  studentId: string,
) {
  return client.cancelQueries(queryKeys.purchases(studentId))
}

export function useStudent(studentId: string) {
  const studentResult = useQuery({
    queryKey: queryKeys.student(studentId),
    queryFn: ({ signal = null }) => getStudent(studentId, { signal }),
  })

  return { student: studentResult.data }
}

export function useStudentAvatars(studentId: string) {
  const studentAvatarsResult = useQuery({
    queryKey: queryKeys.purchases(studentId),
    queryFn: ({ signal = null }) => getStudentPurchases(studentId, { signal }),
  })

  return {
    avatarList: studentAvatarsResult.data,
  }
}
